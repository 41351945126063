import { Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import AddShoppingCartRoundedIcon from "@material-ui/icons/AddShoppingCartRounded"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"


const UseStyles = makeStyles(theme => ({
  root: {

    overflow: "hidden"
  },
  container: {
    maxWidth: 600,
    background: "white",
    marginTop: "-20px"
  },
  paper: {
    padding: 10
  },
  image600: {
    width: '100%',
    height: '100%',
    background:
      "linear-gradient(135deg, #A8A1AD 0%, #9B99AD 41.15%, #A7A2B2 78.65%, #BFBCC4 100%)"
    // background: 'white'
  },
  paper345x100: {
    maxWidth: 345,
    minHeight: 200
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem'
  },

  descriptionBox: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',

    bottom: 0,
    width: '99vw',
    display: 'flex',
    flexWrap: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px 20px 0px 0px',
    marginBottom: '5vh',
    marginTop: '5vh',
    background: '#22262A',
    minHeight: '100vh'


  },
  card: {
    height: '50vh',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  Paper: {
    padding: '1rem'
  }





}))

const productPageTemplate = ({ pageContext }) => {
  const classes = UseStyles()
  const data = pageContext.data
  const availability = data.availableUnits
  const brand = data.brand
  const imageArray = data.images
  const sku = `${data.sku}${data.variationSku}`
  const title = data.title
  const variationList = data.variationList
  const type = data.Category
  const price = data.price
  const id = data.id
  const productLink = `/shop/${data.slug}`
  const image = getImage(data.mainImage)
  const weight = data.weight
  const length = data.itemLength
  const height = data.itemHeight
  const width = data.itemWidth
  const name = title
  const html = data.productContextMd.childMarkdownRemark.html
  console.log(data)


  return (
    <>

      <Layout>
        <Grid container>
          {/* image area */}
          <Grid item xs={12} md={6}>
            <GatsbyImage
              className={classes.image600}
              image={image}
              alt={title}
            />
            {/* Text Area */}

          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.card}>
              <Typography variant="h1" align='center'>{title}</Typography>
              <Link to={`/${brand}`}>
                <Typography variant="h4" align='center'>{brand}</Typography>
              </Link>
              <Typography align='center' variant='h5'>${price}</Typography>
              <div className={classes.buttonContainer}>

                <Button
                  id={id}
                  size='large'
                  className="snipcart-add-item"
                  data-item-shippable={true}
                  data-item-taxable={true}
                  data-item-has-taxes-included={false}
                  data-item-id={id}
                  data-item-price={price}
                  data-item-url={productLink}
                  data-item-description={title}
                  data-item-image={image}
                  data-item-name={title}
                  // data-item-custom1-name={variationType}
                  // data-item-custom1-options={variations}
                  data-item-weight={weight}
                  data-item-length={length}
                  data-item-height={height}
                  data-item-width={width}
                  data-item-categories={type}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  startIcon={<AddShoppingCartRoundedIcon />}
                  key={id}
                  onClick={() => { }}>
                  Add to cart
                </Button>
              </div>


            </div>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <Typography align='center' variation='body1'>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Typography>

        </Paper>
      </Layout>


    </>


  )
}

export default productPageTemplate

    //       
    // \

    //           <div className={classes.section1}>
    //             <Grid container spacing={1} alignContent="center" alignItems='center' className={classes.mainContainer100}>
    //               <Grid item xs={12} sm={8} md={6} className={classes.gridItem}>
    //                 <Paper elevation={0} className={classes.image600}>
    //                   <GatsbyImage
    //                     className={classes.image600}
    //                     image={image}
    //                     alt={title}
    //                   />
    //                 </Paper>
    //               </Grid>
    //             </Grid>
    //           <Grid item xs={12} sm={4} md={6} >
    //             
    //             </div>

    //           </Grid>

    //         </div>
    //       </div>







    //       <div className={classes.section2}>
    //         <Grid container>
    //           <Grid item xs={12} md={12} lg={12} className={classes.description}>

    //             <Typography variation='body1'>
    //               <div dangerouslySetInnerHTML={{ __html: html }} />
    //             </Typography>

    //           </Grid>
    //           <Grid container className={classes.bottomPart} spacing={2}>
    //             <Grid item xs={12} sm={6} md={3}>
    //               <Paper className={classes.paper345x100}>
    //                 <Typography variant="h4">100% authentic product!</Typography>
    //                 <Typography variant="body1">
    //                   As a brand ourselves, we've encountered many copy-cats and posers
    //                   along the way. We guarantee you, everything sold on our site is
    //                   authentic and not fake shit!
    //                 </Typography>
    //               </Paper>
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //               <Paper className={classes.paper345x100}>
    //                 <Typography variant="h4">Fast Shipping Guarantee</Typography>
    //                 <Typography variant="body1">
    //                   We shop online too and we completely understand the frustration of
    //                   waiting for a shipping confirmation! We promise you, we will do
    //                   everything possible to get your order out ASAP!
    //                 </Typography>
    //               </Paper>
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //               <Paper className={classes.paper345x100}>
    //                 <Typography variant="h4">Secured Checkout</Typography>
    //                 <Typography variant="body1">
    //                   We absolutely understand shopping online can be sketchy AF,
    //                   especially from a website other than Amazon... We guarantee you as
    //                   developers and consumers of products from online vendors, we will
    //                   do everything to protect your data and WILL NEVER share it with
    //                   anybody else.
    //                 </Typography>
    //               </Paper>
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //               <Paper className={classes.paper345x100}>
    //                 <Typography variant={"h4"}>
    //                   Stealth (nosey-neighbor-protection)
    //                 </Typography>
    //                 <Typography variant="body1">
    //                   Every neighborhood has that KAREN waiting to oust whomever they
    //                   feel like trolling. We at Samurai Blaze have definitely dealt with
    //                   a few of them. Our packaging blends in perfectly with any brown
    //                   box or bubble mailer you'd get from amazon.
    //                 </Typography>
    //               </Paper>
    //             </Grid>
    //           </Grid>
    //         </Grid>

    //       </div>
    //       </div>
    //       {/* <iframe src="https://www.youtube.com/embed/tmkn_51Uvt8?autoplay=1/" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */ }
    //     </Layout >



